import { Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { Initialization } from "@microsoft/applicationinsights-web/types/Initialization";
import { environment } from "src/environments/environment";
import { Logger } from "../logger/logger";

@Injectable({ providedIn: "root" })
export class ServiceWorkerUpdateService {
  constructor(private swUpdate: SwUpdate, private logger: Logger) {
    this.logger.info("*** ServiceWorkerUpdateService - constructor");
  }

  public initialize() {
    // Initialization is done in constructor when injecting
    this.logger.info2(
      "*** ServiceWorkerUpdateService - swUpdate - isEnabled ",
      this.swUpdate.isEnabled
    );

      if (!this.swUpdate.isEnabled) {
        return;
      }

    // do we need this??
    //if (this.swUpdate.isEnabled) {
    //this.subs.push(
    // normally we don't need to unsub from a service worker
    this.swUpdate.available.subscribe((event) => {
      this.logger.info2("*** current version is", event.current);
      this.logger.info2("*** available version is", event.available);

      window.location.reload();
    });

    this.swUpdate.activated.subscribe((event) => {
      this.logger.info2("old version was", event.previous);
      this.logger.info2("new version is", event.current);
    });
    // } else {
    //   this.logger.info("*** INFO: swUpdate is not enabled");
    // }

    // always log the version
    console.log("Plaatsbeschrijvingen | Version: " + environment.appVersion);
  
    this.swUpdate.checkForUpdate();
  }
}
