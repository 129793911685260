import * as _ from "lodash-es";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { Injectable, OnDestroy } from "@angular/core";
import { ItemGroup } from "../models/itemgroup.model";
import { Room } from "../models/room.model";
import { LocationDescription } from "../models/locationdescription.model";
import { Logger } from "../logger/logger";
import { MessageService } from "../services/message.service";
import { DataService } from "../services/data.service";
import { Router } from "@angular/router";
import { StorageMap } from "@ngx-pwa/local-storage";
import { map, takeUntil } from "rxjs/operators";
import { forkJoin } from "rxjs";
import * as moment from "moment";
import { UserLog } from "../models/userlog.model";
import { EraUser } from "../models/user.model";
import { Office } from "../models/office.model";
import { MsalService } from "@azure/msal-angular";
import { AccountInfo } from "@azure/msal-browser";

@Injectable({
  providedIn: "root",
})
export class StateStore implements OnDestroy {
  private onDestroy$: Subject<void> = new Subject();
  // plain values no observables
  public showProgressbar: boolean;
  public isInitializedFlag$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public online$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public debugFlag$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  // =======================
  // prototypes
  public itemGroups$: BehaviorSubject<ItemGroup[]> = new BehaviorSubject<
    ItemGroup[]
  >(null);
  public users$: BehaviorSubject<EraUser[]> = new BehaviorSubject<
    EraUser[]
  >(null);
  public currentUser$: BehaviorSubject<EraUser> = new BehaviorSubject<
    EraUser
  >(null);
  public currentOffice$: BehaviorSubject<Office> = new BehaviorSubject<
  Office
>(null);
  public rooms$: BehaviorSubject<Room[]> = new BehaviorSubject<Room[]>(null);
  public locationdescriptions$: BehaviorSubject<LocationDescription[]> =
    new BehaviorSubject<LocationDescription[]>([]);

  // current value objects for navigation
  public currentLocationDescription$: BehaviorSubject<LocationDescription> =
    new BehaviorSubject<LocationDescription>(null);
  public currentLocationDescriptionRoom$: BehaviorSubject<Room> =
    new BehaviorSubject<Room>(null);
  public currentLocationDescriptionItemGroup$: BehaviorSubject<ItemGroup> =
    new BehaviorSubject<ItemGroup>(null);

  public userlog$: BehaviorSubject<UserLog[]> = new BehaviorSubject<UserLog[]>(
    []
  );
  public isNotResponsible$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public locationDescriptionsUpdatedOffline$: BehaviorSubject<LocationDescription[]> = new BehaviorSubject<LocationDescription[]>(
    []
    );
  public initialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
      
  private locationDescriptionsUpdatedOffline: LocationDescription[] = [];
  private overwrite: LocationDescription[] = [];
  private tasks$: Observable<any>[] = [];
  private offlineTasks$: Observable<any>[] = [];
  // =======================

  constructor(
    private logger: Logger,
    private messageService: MessageService,
    private storage: StorageMap,
    private dataService: DataService,
    private router: Router,
    private msalService: MsalService
  ) {
    this.initialized$.next(false);
    this.logger.info("StateStore - constructor");
    this.debugFlag$.next(localStorage.getItem("debug") == "true");
  }

  public initialize(): void {
    this.initialized$.next(true);
    this.messageService.showProgressSpinner();
    this.logger.info("*** StateStore - initialize");
    this.showProgressbar = false; // initialize progressbar DO NOT SET to True because then the logic is broken!!!
    this.getIsOnlineFromBackend();
  }

  public getIsOnlineFromBackend(overwrite: LocationDescription[] = []): any {
    this.overwrite = overwrite;
    //this.logger.info("!!! ping 1");
    this.dataService.checkIsOnline().pipe(takeUntil(this.onDestroy$)).subscribe(
      (res) => {
        this.initializeWhenOnline();
        localStorage.setItem('isOnline',res.toString());
      },
      (error) => {
        this.initializeWhenOffline(error);
        localStorage.setItem('isOnline','false');
      }
    );
  }

  private initializeWhenOnline(): void {
    this.online$.next(true);
    this.logger.info("*** StateStore - isOnlineFlag");
    if (this.msalService.instance.getAllAccounts().length > 0) {
        this.logger.info("*** StateStore - isAuthenticated");
        this.messageService.showMessage("synchroniseren gegevens");
        this.logUser("synchroniseren gegevens");

        this.handleOnlineSyncProcessLocationDesciptions();
        this.getRoomItemUsers();
    }
  }

  private getRoomItemUsers(): void {
        // these are standing stand alone, oke to load them on initialize
        this.getDataFromBackend_RoomPrototypes();
        this.getDataFromBackend_ItemGroupPrototypes();
        this.getDataFromBackend_Users();
  }

  public initializeWhenOffline(error: any): void {
    this.online$.next(false);

    this.messageService.showWarning("working offline");
    this.router.navigateByUrl('/location-decription-list');
    this.getStateFromLocalStorage_ALL();
    this.messageService.showMessage(error);
    this.showProgressbar = false;
    this.messageService.hideProgressSpinner();
  }

  public getAllDataFromBackEnd(): void {
    this.messageService.showProgressSpinner();
    this.logger.info("*** StateStore - getAllDataFromBackEnd");
    this.logUser("synchroniseren gegevens");
    this.router.navigateByUrl("/");
    this.getDataFromBackend_LocationDescriptions();
    this.getRoomItemUsers();
  }

  private handleOnlineSyncProcessLocationDesciptions() {
    // done...
    // this.clearState();

    // retrieve state from indexed db
    this.dataService.getLocationDescriptionsOnly().pipe(takeUntil(this.onDestroy$)).subscribe((simpleLocationDescriptions: LocationDescription[]) => {
      this.locationDescriptionsUpdatedOffline = [];
      this.getStateFromLocalStorage_LocationDescriptions().pipe(takeUntil(this.onDestroy$)).subscribe((res) => {
        this.getStateFromLocalStorage_InitialLocationDescriptions().pipe(takeUntil(this.onDestroy$)).subscribe((initialLocationDescriptions: LocationDescription[]) => {
          if (res) {
            this.logger.info("LocationDescriptions => from local storage");
            this.logger.info(res);

            // if res null there is not local data present
            //this.locationdescriptions$.next(res as LocationDescription[]); /// !!!!
            let locationDescriptionsToSend: LocationDescription[] =
              res as LocationDescription[];
            // here we could show these to the user... but there is still a retrieval pending
            locationDescriptionsToSend.forEach((locationDescription) => {
              // TODO check if is modified, if not than do not send !!!!
              this.logger.info(
                "*** " +
                  locationDescription.Id +
                  " = " +
                  locationDescription.DateModified
              );

              if (locationDescription.IsNewSyncFlag === true) {
                this.logger.info("*** New  ");
                this.tasks$.push(
                  this.dataService.updateCurrentLocationDescription(
                    {...locationDescription},
                    this.userlog$.value,
                  )
                );
              } else {
                if(!this.overwrite.length && localStorage.getItem('updateSilently') !== 'true') {
                  this.checkLocationDescriptionChanged({...locationDescription}, initialLocationDescriptions, simpleLocationDescriptions);
                }
                localStorage.setItem('updateSilently','false');
              }
            });
            //if overwrite flag just push new api call
            if(this.overwrite.length) {
              this.overwrite.forEach( o => {
                o.DateModified = moment().toISOString(true);
                this.tasks$.push(
                  this.dataService.updateCurrentLocationDescription(
                    {...o},
                    this.userlog$.value,
                  )
                );
              });
            }

            console.log("##############################");
            console.log(this.tasks$.length);
            if (this.tasks$.length === 0 && this.offlineTasks$.length === 0) {
                //no tasks to update
                this.locationDescriptionsUpdatedOffline$.next([]);
                this.logUser("geen veranderingen ...");
                this.getDataFromBackend_LocationDescriptions();
              } else {
                //there are tasks to update 
                //we didn't select anything to overwrite and we updated things offline
                if(!this.overwrite.length && this.locationDescriptionsUpdatedOffline.length){
                  if(this.offlineTasks$.length && this.tasks$.length){
                    //we are overwriting or we have tasks
                    forkJoin(this.tasks$).pipe(takeUntil(this.onDestroy$)).subscribe((results) => {
                      this.messageService.showMessage(
                        "syncronisatie gegevens voltooid"
                      );
                      this.logUser("datagevens verzenden voltooid ...");
                      if(results.length && results[0]){
                        this.messageService.showError(results.toString());
                      }
                      this.tasks$ = [];
                      this.offlineTasks$ = [];
                    });
                  }
                  this.tasks$ = [];
                  this.offlineTasks$ = [];
                  this.locationDescriptionsUpdatedOffline$.next(this.locationDescriptionsUpdatedOffline);
                }
                else {
                  //we are overwriting or we have tasks
                  forkJoin(this.tasks$).pipe(takeUntil(this.onDestroy$)).subscribe((results) => {
                    this.messageService.showMessage(
                      "syncronisatie gegevens voltooid"
                    );
                    this.logUser("datagevens verzenden voltooid ...");
                    if(results.length && results[0]){
                      this.messageService.showError(results.toString());
                    }
                    this.getDataFromBackend_LocationDescriptions();
                    this.tasks$ = [];
                    this.offlineTasks$ = [];
                    this.locationDescriptionsUpdatedOffline$.next([]);
                  });
              }
            }
          } else {
            this.logUser("geen lokale data aanwezig...");
            this.getDataFromBackend_LocationDescriptions();
            this.locationDescriptionsUpdatedOffline$.next([]);
            this.offlineTasks$ = [];
          }
      });
    });
  },
  (error: any)=>{
    if(error.indexOf('User Inactive')>-1){
      this.router.navigateByUrl('/inactive-user');
    }
    this.messageService.hideProgressSpinner();
  });
  }
  
  
  public updateSilently(ld: LocationDescription): void {
    localStorage.setItem('updateSilently','true');
    this.dataService.updateCurrentLocationDescription(ld,this.userlog$.value).subscribe(
      () => this.updateLocationDescriptionDate(),
      ()=> localStorage.setItem('updateSilently','false'));
  }

  private updateLocationDescriptionDate(): void {
    this.dataService.getLocationDescriptionsOnly().pipe(takeUntil(this.onDestroy$)).subscribe((simpleLocationDescriptions: LocationDescription[]) => {
      const tst = simpleLocationDescriptions.filter(sld => sld.Id === this.currentLocationDescription$.value.Id);
      if(tst.length){
        this.currentLocationDescription$.value.DateModified = tst[0].DateModified;
        this.locationdescriptions$.next(this.locationdescriptions$.value.map(e => {
          if(e.Id === this.currentLocationDescription$.value.Id){
            e.DateModified = tst[0].DateModified;
          }
          return e;
        }));
        this.setToLocalStorage_InitialLocationDescriptions(this.locationdescriptions$.value);
        this.setToLocalStorage_State_LocationDescription();
        localStorage.setItem('updateSilently','false');
      }
    },
    ()=> localStorage.setItem('updateSilently','false'),
    () => localStorage.setItem('updateSilently','false'));
  }

  private checkLocationDescriptionChanged(locationDescription: LocationDescription, initialLocationDescriptions: LocationDescription[], simpleLocationDescriptions: LocationDescription[]) {
    //check if the locationDescription was changed since last load
      if(this.locationDescriptionAreDifferent(locationDescription, initialLocationDescriptions)){
          this.checkLocationDescriptionChangedWhileOffline(locationDescription, simpleLocationDescriptions);
      }
  }

  private checkLocationDescriptionChangedWhileOffline(locationDescription: LocationDescription, simpleLocationDescriptions: LocationDescription[]) {
    this.getStateFromLocalStorage_LocationDescriptions().pipe(takeUntil(this.onDestroy$)).subscribe((res) => {

    });
    if(this.locationDescriptionFromBEUpdatedAfter(locationDescription, simpleLocationDescriptions)) {
      this.locationDescriptionsUpdatedOffline.push({...locationDescription});
      this.offlineTasks$.push(
        this.dataService.updateCurrentLocationDescription(
          {...locationDescription},
          this.userlog$.value,
        )
      );
      this.messageService.hideProgressSpinner();
      this.router.navigateByUrl("/overwrite");
    } else if(this.locationDescriptionFromBEDifferent(locationDescription, simpleLocationDescriptions)) {
      this.tasks$.push(
        this.dataService.updateCurrentLocationDescription(
          {...locationDescription},
          this.userlog$.value,
        )
      );
    }
  }
  private locationDescriptionFromBEDifferent(locationDescription: LocationDescription, locationDescriptions: LocationDescription[]): boolean {
    let BELocationDescription = locationDescriptions?.find(l => l.Id === locationDescription.Id);
    return (new Date(BELocationDescription?.DateModified).getTime() !== new Date(locationDescription?.DateModified).getTime()) && BELocationDescription?.Id === locationDescription?.Id;
  }

  private locationDescriptionFromBEUpdatedAfter(locationDescription: LocationDescription, locationDescriptions: LocationDescription[]): boolean {
    let BELocationDescription = locationDescriptions?.find(l => l.Id === locationDescription.Id);
    let initialDate = locationDescription?.DateModified.indexOf('+') > -1 ? new Date(locationDescription?.DateModified.split('+')[0]) : new Date(locationDescription?.DateModified);
    let numberToSubtract = locationDescription?.DateModified.indexOf('+') > -1 && locationDescription?.DateModified.split('+')[1].indexOf(':') > -1 ? (locationDescription?.DateModified.split('+')[1].split(':')[0] as any)*1 : 0;
    let date =  new Date(initialDate.setHours(initialDate.getHours()-numberToSubtract));
    return (new Date(BELocationDescription?.DateModified).getTime() > date.getTime()) && BELocationDescription?.Id === locationDescription?.Id;
  }

  private locationDescriptionAreDifferent(locationDescription: LocationDescription, locationDescriptions: LocationDescription[]): boolean {
    let initalLocationDescription = locationDescriptions?.find(l => l.Id === locationDescription.Id);
    return (new Date(initalLocationDescription?.DateModified).getTime() !== new Date(locationDescription?.DateModified).getTime()) && initalLocationDescription?.Id === locationDescription?.Id;
  }

  // this is
  private getStateFromLocalStorage_ALL() {
    // get all data from local storage, if all executed, do qual check
      this.getStateFromLocalStorage_LocationDescriptions().pipe(takeUntil(this.onDestroy$)).subscribe(
        (res) => {
          if (res) {
            this.locationdescriptions$.next(res as LocationDescription[]);
          }
        },
        (error: any) => {
          this.logUser("Error");
          this.messageService.showError("Error");
        }
      );
    this.getStateFromLocalStorage_RoomPrototypes();
    this.getStateFromLocalStorage_ItemGroupPrototypes();
    this.getStateFromLocalStorage_Users();
  }

  public getStateFromLocalStorage_LocationDescriptions(): Observable<
    LocationDescription[]
  > {
    return this.storage.get("location-descriptions").pipe(
      map((res) => {
        return res as LocationDescription[];
      })
    );
  }

  private getStateFromLocalStorage_InitialLocationDescriptions(): Observable<
    LocationDescription[]
  > {
    return this.storage.get("initial-location-descriptions").pipe(
      map((res) => {
        return res as LocationDescription[];
      })
    );
  }

  private getStateFromLocalStorage_RoomPrototypes(): Subscription {
    return this.storage.get("room-prototypes").pipe(takeUntil(this.onDestroy$)).subscribe(
      (res) => {
        if (res) {
          this.rooms$.next(res as Room[]);
        } else {
          this.messageService.showWarning("RoomPrototypes not present");
          this.router.navigateByUrl("/pages/offline-nodata");
        }
      },
      (error: any) => {
        this.messageService.showError("Error");
        this.logger.error(error);
      }
    );
  }

  private getStateFromLocalStorage_ItemGroupPrototypes(): Subscription {
    return this.storage.get("itemgroup-prototypes").pipe(takeUntil(this.onDestroy$)).subscribe((res) => {
      if (res) {
        this.itemGroups$.next(res as ItemGroup[]);
      } else {
        this.messageService.showWarning("ItemGroupPrototypes not present");
        this.router.navigateByUrl("/pages/offline-nodata");
      }
    });
  }

  private getStateFromLocalStorage_Users(): Subscription {
    return this.storage.get("users").pipe(takeUntil(this.onDestroy$)).subscribe((res) => {
      if (res) {
        this.setUsersAndOffices(res as EraUser[]);
      } else {
        this.messageService.showWarning("Users not present");
        this.router.navigateByUrl("/pages/offline-nodata");
      }
    });
  }
  //-//-//-//-//-//-//-//-//

  recalculateRoomSortOrderCurrentLocationDescription() {
    let sortIndex = 1;
    this.currentLocationDescription$.value.Rooms.forEach((room) => {
      room.SortOrder = sortIndex++;
    });
  }

  recalculateItemGroupSortOrderCurrentLocationDescriptionRoom() {
    let sortIndex = 1;
    this.currentLocationDescriptionRoom$.value.ItemGroups.forEach(
      (itemGroup) => {
        itemGroup.SortOrder = sortIndex++;
      }
    );
  }

  //-//-//-//-//-//-//-//-//
  // State loading using Backend calls
  public getDataFromBackend_LocationDescriptions() {
    this.logger.info(
      "*** StateStore - getDataFromBackend_LocationDescriptions"
    );

    this.logUser("data ophalen van server ...");
    this.dataService.getLocationDescriptions().pipe(takeUntil(this.onDestroy$)).subscribe(
      (locationDescriptions: LocationDescription[]) => {
        if (locationDescriptions) {
          this.logger.info(
            "getLocationDescriptions res => data opgehaald van server ..."
          );
          this.logger.info(locationDescriptions);
          this.logUser("data opgehaald van server ...");

          // show location descriptions to the user
          this.locationdescriptions$.next(locationDescriptions);
          this.messageService.hideProgressSpinner();

          // store the location descriptions retrieved from backend to the local storage
          this.setToLocalStorage_LocationDescriptions(locationDescriptions);
          this.setToLocalStorage_InitialLocationDescriptions(locationDescriptions);
        } else {
          this.messageService.showError("ERROR Unexpected State: code 188");
          this.logUser("ERROR Unexpected State: code 188 ...");
        }

        this.showProgressbar = false; // this postition is not exact correct ....
      },
      (error: any) => {
        this.messageService.hideProgressSpinner();
        this.messageService.showError("Error");
        this.logger.error(error);
      }
    );
  }

  private getDataFromBackend_RoomPrototypes() {
    this.logger.info("*** StateStore - getDataFromBackend_RoomPrototypes");
    this.logUser("kamer templates ophalen");

    this.dataService.getRoomPrototypes().pipe(takeUntil(this.onDestroy$)).subscribe(
      (rooms: Room[]) => {
        this.rooms$.next(_.orderBy(rooms, "Name", "asc")); // this order by can be done in backend!!
        this.setToLocalStorage_State_Rooms();
        this.messageService.showMessage("kamer templates geladen");
        this.logUser("kamer templates geladen");
      },
      (error: any) => {
        this.messageService.showError("Error");
        this.logger.error(error);
      }
    );
  }

  private getDataFromBackend_ItemGroupPrototypes() {
    this.logger.info("*** StateStore - getDataFromBackend_ItemGroupPrototypes");
    this.logUser("optiegroepen ophalen");
    this.dataService.getItemGroupPrototypes().pipe(takeUntil(this.onDestroy$)).subscribe(
      (itemGroups: ItemGroup[]) => {
        this.itemGroups$.next(_.orderBy(itemGroups, "Id", "asc")); // this order by can be done in backend!!
        this.setToLocalStorage_State_ItemGroups();
        this.messageService.showMessage("optiegroepen laden");
        this.logUser("optiegroepen geladen");
      },
      (error: any) => {
        this.messageService.showError("Error");
        this.logger.error(error);
      }
    );
  }

  private getDataFromBackend_Users(): void {
    this.logger.info("*** StateStore - getDataFromBackend_Users");
    this.logUser("optiegroepen ophalen");
    this.dataService.getUsers().pipe(takeUntil(this.onDestroy$)).subscribe(
      (users: EraUser[]) => {
        this.setUsersAndOffices(users);
        this.messageService.showMessage("optiegroepen laden");
        this.logUser("optiegroepen geladen");
      },
      (error: any) => {
        this.messageService.showError("Error");
        this.logger.error(error);
      }
    );
  }

  private setUsersAndOffices(users: EraUser[]): void {
    this.users$.next(users);
    this.setToLocalStorage_State_Users();
    this.currentUser$.next(users?.find(u => u.Email === this.msalService.instance.getActiveAccount()?.username));
    this.setCurrentOffice();
  }

  private setCurrentOffice(): void {
    this.currentOffice$.next(null);
    this.storage.get("currentOffice").subscribe( (currentOffice: any) => {
      if(this.currentUser$.value?.Offices.find(o => o.Id === currentOffice?.Id)){
        this.currentOffice$.next(currentOffice);
      } else if(this.currentUser$.value?.Offices.length){
        this.currentOffice$.next(this.currentUser$.value?.Offices[0]);
      }
      this.setToLocalStorage_State_Current_Office();
    });
  }

  // ... End State loading using Backend calls
  //-//-//-//-//-//-//-//-//
  public setToLocalStorage_LocationDescriptions(
    locationdescriptions: LocationDescription[]
  ): Subscription {
    this.storage.delete("location-descriptions");
    this.logger.info("*** StateStore - setToLocalStorage_LocationDescriptions");
    this.logUser("data lokaal opslaan (plaatsbeschrijvingen)...");

    return this.storage
      .set("location-descriptions", locationdescriptions)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (res) => {
          this.messageService.showSave();
          this.logUser("data lokaal opgeslagen, compleet...");
        },
        (error: any) => {
          this.logUser("ERROR data lokaal opgeslagen, niet compleet...");
          this.messageService.showError("Error");
          this.logger.error(error);
        }
      );
  }

  // ... End State loading using Backend calls
  //-//-//-//-//-//-//-//-//
  public setToLocalStorage_InitialLocationDescriptions(
    locationdescriptions: LocationDescription[]
  ): Subscription {
    this.storage.delete("initial-location-descriptions");
    this.logger.info("*** StateStore - setToLocalStorage_LocationDescriptions");
    this.logUser("data lokaal opslaan (plaatsbeschrijvingen)...");

    return this.storage
      .set("initial-location-descriptions", locationdescriptions)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (res) => {
          this.messageService.showSave();
          this.logUser("data lokaal opgeslagen, compleet...");
        },
        (error: any) => {
          this.logUser("ERROR data lokaal opgeslagen, niet compleet...");
          this.messageService.showError("Error");
          this.logger.error(error);
        }
      );
  }

  /// ========== SECTION Setting state to storage  ==========
  public setToLocalStorage_State_LocationDescription(): Subscription {
    this.logUser("data status lokaal opslaan (plaatsbeschrijvingen)...");
    this.storage.delete("location-descriptions");
    if (this.currentLocationDescription$.value) {
      this.currentLocationDescription$.value.DateModified = moment().toISOString(true);
    }

    return this.storage
      .set("location-descriptions", this.locationdescriptions$.value)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (res) => {
          this.messageService.showSave();
          this.logUser("data status lokaal opgeslagen, compleet...");
        },
        (error: any) => {
          this.logUser("ERROR data lokaal opgeslagen, niet compleet...");
          this.messageService.showError("Error");
          this.logger.error(error);
        }
      );
  }

  // this is for lock and unlock
  public setToLocalStorage_State_LocationDescription_and_reload() {
    this.storage.delete("location-descriptions");
    return this.storage
      .set("location-descriptions", this.locationdescriptions$.value)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (res) => {
          this.messageService.showSave();
          this.logUser("data status lokaal opgeslagen, compleet...");
          window.location.reload();
        },
        (error: any) => {
          this.logUser("ERROR data lokaal opgeslagen, niet compleet...");
          this.messageService.showError("Error");
          this.logger.error(error);
        }
      );
  }
  public updateLocationDescriptionModifiedDate() : void {
    if (this.currentLocationDescription$.value) {
      this.currentLocationDescription$.value.DateModified = moment().toISOString(true);
        this.locationdescriptions$.next(this.locationdescriptions$.value.map(e => {
          if(e.Id === this.currentLocationDescription$.value.Id){
            e.DateModified = this.currentLocationDescription$.value.DateModified;
          }
          return e;
        }));
        this.setToLocalStorage_State_LocationDescription();
    }
  }

  public setToLocalStorage_State_Rooms(): Subscription {
    this.storage.delete("room-prototypes");
    this.logUser("data status lokaal opslaan (kamers)...");
    return this.storage.set("room-prototypes", this.rooms$.value)
      .pipe(takeUntil(this.onDestroy$)).subscribe();
  }

  public setToLocalStorage_State_ItemGroups(): Subscription {
    this.logUser("data status lokaal opslaan (item groepen)...");

    this.storage.delete("itemgroup-prototypes");
    return this.storage
      .set("itemgroup-prototypes", this.itemGroups$.value)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }

  public setToLocalStorage_State_Users(): Subscription {
    this.storage.delete("users");
    return this.storage
      .set("users", this.users$.value)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }

  setToLocalStorage_State_Current_Office(): Subscription {
    this.storage.delete("currentOffice");
    return this.storage
      .set("currentOffice", this.currentOffice$.value)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }

  /// ========== SECTION Setting state to storage  ==========

  public addLocationDescription(locationDescription: LocationDescription) {
    this.locationdescriptions$.value.push(locationDescription);
    this.currentLocationDescription$.next(locationDescription);
    //    console.log(this.locationdescriptions$.value);
  }

  //  HOUSEKEEPING ;-) //
  clearState() {
    localStorage.clear();
    this.logger.info("*** StateStore - clearState");
    this.itemGroups$ = new BehaviorSubject<ItemGroup[]>(null);
    this.rooms$ = new BehaviorSubject<Room[]>(null);
    this.locationdescriptions$ = new BehaviorSubject<LocationDescription[]>([]);

    // current value objects for navigation
    this.currentLocationDescription$ = new BehaviorSubject<LocationDescription>(
      null
    );
    this.currentLocationDescriptionRoom$ = new BehaviorSubject<Room>(null);
    this.currentLocationDescriptionItemGroup$ = new BehaviorSubject<ItemGroup>(
      null
    );
  }

  clearLog() {
    this.userlog$ = new BehaviorSubject<UserLog[]>([]);
  }

  logUser(messageLine: string) {
    this.logger.info("log: " + messageLine);
    this.userlog$.value.push({
      message: messageLine,
      sort: this.userlog$.value.length + 1,
    });
    this.userlog$.next(_.orderBy(this.userlog$.value, ["sort"], ["desc"]));
  }

  clearStore() {
    this.logger.info("*** StateStore - clearStore");

    this.clearState();

    this.storage.clear().pipe(takeUntil(this.onDestroy$)).subscribe((res) => {
      this.messageService.showMessage("clearStore");
      this.clearState();
    });
  }

  ngOnDestroy(): void {
    this.logger.info("*** StateStore - ngOnDestroy");
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
  
  public createUser(): any {
    this.messageService.showProgressSpinner();
    if(!this.msalService.instance.getActiveAccount()){
      return null;
    }
    let newUser = {
      Id: this.msalService.instance.getActiveAccount().localAccountId,
      Name: this.msalService.instance.getActiveAccount().name,
      Email: this.msalService.instance.getActiveAccount().username,
      DateCreated: new Date(0),
      DateModified: new Date(0),
    } as EraUser;
    this.logUser("checking user");
    this.dataService.createUser(newUser).subscribe(
      (res) => {
        if(!res.IsActive){
          this.router.navigateByUrl('/inactive-user');
          this.messageService.hideProgressSpinner();
        }
        else {
          this.router.navigateByUrl('/location-description-list');
          this.initialize();
        }
        this.logger.info("*** USER ADDED  ");
      },
      error=> {
        this.getStateFromLocalStorage_LocationDescriptions().subscribe(res =>{
          if(res?.length <= 0){
            if(error?.indexOf("0 Unknown Error")>-1){
              this.router.navigateByUrl('/pages/offline-nodata');
            }
          } else {
            this.router.navigateByUrl('/location-decription-list');
          }
        });
        this.messageService.hideProgressSpinner();
      });
  }

  public setToLocalStorageUser(account: AccountInfo): void {
    localStorage.setItem('localAccountId',account.localAccountId);
    localStorage.setItem('name',account.name);
    localStorage.setItem('username',account.username);
  }
}
