import { Injectable, OnDestroy } from "@angular/core";
import {
  CanActivate, Router,
} from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { Subject } from "rxjs";
import { ServiceWorkerUpdateService } from "src/app/shared/service-worker/update-service";
import { StateStore } from "src/app/shared/state/app.state-store";

@Injectable({
  providedIn: "root",
})
export class InitializeGuard implements CanActivate, OnDestroy {
  private onDestroy$: Subject<void> = new Subject();
  
  constructor(
    private msalService: MsalService,
    private serviceWorkerUpdateService: ServiceWorkerUpdateService,
    private state: StateStore,
    private router: Router
  ) {}

  canActivate(): any {
    this.serviceWorkerUpdateService.initialize();
      if(!this.state.initialized$.value) {
        if(localStorage.getItem('isOnline') === 'true'){
          if(this.msalService.instance.getActiveAccount() && !this.state.locationdescriptions$.value.length){
            let timeDiff = Math.abs(new Date(this.msalService.instance.getActiveAccount().idTokenClaims.exp * 1000).getTime() - new Date().getTime()) / 3600000;
            if(timeDiff > 5){
                this.router.navigateByUrl('/login');
            }
              this.state.initialize();
            }
          }
          // return true;
        } else if(!localStorage.getItem('username')){
          this.state.clearState();
          this.router.navigateByUrl('/login');
        }
      return true;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}