import { Component, OnInit } from "@angular/core";
import { ThemePalette } from "@angular/material/core";
import { ProgressSpinnerMode } from "@angular/material/progress-spinner";
import { StateStore } from "../../state/app.state-store";

@Component({
  selector: "app-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.scss"],
})
export class SpinnerComponent implements OnInit {
  private counter: number;
  public color: ThemePalette = "primary";
  public icon: string = "hourglass_empty";
  public showResult = false;

  constructor(public state: StateStore) {
    // 10 sec collor changer of the spinner
    this.counter = 10;
  }

  ngOnInit(): void {
    let intervalId = setInterval(() => {
      this.counter = this.counter - 1;
      if (this.counter === 0) clearInterval(intervalId);
      // switch between collors every sec
      if (this.counter % 2 == 0) {
        this.color = "warn";
        this.icon = "hourglass_full";
      } else if (this.counter % 3 == 0) {
        this.color = "accent";
        this.icon = "hourglass_top";
      } else {
        this.color = "primary";
        this.icon = "hourglass_bottom";
      }
    }, 1000);
    this.showResult = localStorage.getItem("showResult") === 'true' ? true : false;
  }

  public mode: ProgressSpinnerMode = "indeterminate";
}
