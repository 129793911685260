import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { AppInsightsService } from "../services/app-insights.service";

@Injectable({
  providedIn: "root",
})
export class Logger {
  private debug: boolean =
    localStorage.getItem("debug") == "true" ? true : false;
  constructor(private appInsights: AppInsightsService) {}

  log(obj: any) {
    if (this.debug) {
      console.log(obj);
    }
  }

  info(obj: any) {
    if (this.debug) {
      console.info(obj);
    }
  }
  info2(obj: any, obj2: any) {
    if (this.debug) {
      console.info(obj, obj2);
    }
  }
  error(obj: any) {
    console.error(obj);
    try {
      this.appInsights.logException(obj, 1);
    } catch (error) {
      console.log(error);
    }
  }

  log2(...args: any[]) {
    if (this.debug) {
      console.log.apply(console, args);
    }
  }

  // info(...args) {
  //   if (this.debug) {
  //     console.info.apply(console, args);
  //   }
  // }

  // error(...args) {
  //   console.error.apply(console, args);
  // }

  count(obj: string | undefined) {
    if (this.debug) {
      console.count(obj);
    }
  }

  time(obj: string | undefined) {
    if (this.debug) {
      console.time(obj);
    }
  }

  timeEnd(obj: string | undefined) {
    if (this.debug) {
      console.timeEnd(obj);
    }
  }

  ais_logPageView(name?: string, url?: string) {
    if (environment.appInsightsInstrumentationKey.trim() != "") {
      this.appInsights.logPageView(name, url);
    }
  }

  ais_event(name: string, properties?: { [key: string]: any }) {
    if (environment.appInsightsInstrumentationKey.trim() != "") {
      this.appInsights.logEvent(name, properties);
    }
  }
}
