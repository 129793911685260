<div
  mat-dialog-title
  fxLayoutAlign="start center"
  fxLayout="row"
  fxLayoutGap="2rem"
>
  <!-- <mat-icon *ngIf="dialogData.Icon">{{ dialogData.Icon }}</mat-icon>
  <mat-icon *ngIf="!dialogData.Icon">info_outline</mat-icon> -->
  <mat-icon>{{ icon }}</mat-icon>
  <div>In uitvoering...</div>
  <!-- <div>{{ dialogData.Title }}</div> -->
</div>

<div
  class="p-4"
  mat-dialog-content
  fxLayout="column"
  fxLayoutAlign="space-around center"
>
  <!-- <p class="text-xl">{{ dialogData.Subtitle }}</p> -->
  <!-- <p class="text-xl">...even geduld...</p> -->
  <mat-progress-spinner class="" [color]="color" [mode]="mode" diameter="100">
  </mat-progress-spinner>
</div>

<mat-card *ngIf="showResult">
  <mat-card-content class="h-30 overflow-auto">
    <h2 class="">Result</h2>
    <div class="h-20 overflow-visible">
      <ul>
        <li *ngFor="let item of this.state.userlog$.value; let i = index">
          {{ item.sort }} - {{ item.message }}
        </li>
      </ul>
    </div>
  </mat-card-content>
</mat-card>
