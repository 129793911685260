import * as _ from "lodash-es";
import { environment } from "src/environments/environment";
import { HttpBackend, HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { ItemGroup } from "../models/itemgroup.model";
import { Room } from "../models/room.model";
import { LocationDescription } from "../models/locationdescription.model";
import { ResultMessage } from "../models/resultmessage.model";
import { MessageService } from "./message.service";
import { Logger } from "../logger/logger";
import { Doc } from "../models/doc.model";
import { UserLog } from "../models/userlog.model";
import { EraUser } from "../models/user.model";


@Injectable({ providedIn: "root" })
export class DataService {
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" ,'Access-Control-Allow-Origin': environment.api.replace("$BASE_URL$", location.hostname), 'Accept': 'application/json', 'X-Request': 'JSON'}),
  };

  private customHttpClient: HttpClient;
  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private logger: Logger,
    backend: HttpBackend
  ) {
    this.customHttpClient = new HttpClient(backend);
  }

  public setToken(tokenType: string, accessToken: string): void {
    this.httpOptions.headers = this.httpOptions.headers.append("Authorization", `${tokenType} ${accessToken}`);
  }

  public checkIsOnline(): Observable<boolean> {
    this.logger.info(
      "*** DataService => checkIsOnline()data lokaal opslaan - /health"
    );
    return this.customHttpClient.get<any>(`${environment.api.replace("$BASE_URL$", location.hostname)}/health`).pipe(
      map(
        (result: any) => {
          this.logger.info("*** Result /health => ");
          this.logger.info(result);

          if (result && result.status == "Healthy") {
            localStorage.setItem('isOnline','true');
            return true;
          } else {
            // in case sql down or other healt checks failing
            localStorage.setItem('isOnline','false');
            return false;
          }
        },
        (error: any) => {
          localStorage.setItem('isOnline','false');
          console.error("!!!!! DOES CODE COME HERE???");
          console.error("checkIsOnline() error");
          this.logger.error(error);
          return false;
        }
      )
    );
  }
  // ***********************
  // LocationDescription
  public getLocationDescriptions(): Observable<LocationDescription[]> {
    return this.http
      .get<ResultMessage>(
        `${environment.api.replace("$BASE_URL$", location.hostname)}/locationdescription/locationdescriptions`,
        this.httpOptions
      )
      .pipe(
        map((resultMessage: ResultMessage) => {
          // this.state.locati  ondescrip  tions$.n  ext(
          //   _.orderBy(products, "ProductNameNl", "asc")
          // );
          // let locationdescriptions: LocationDescription[] =
          //   resultMessage.Data as LocationDescription[];
          // let message: string = resultMessage.Message;

          this.messageService.showMessage(resultMessage.Message);

          return resultMessage.Data as LocationDescription[];
        })
      );
  }
  // ***********************

  public getLocationDescriptionsOnly(): Observable<LocationDescription[]> {
    return this.http
      .get<ResultMessage>(
        `${environment.api.replace("$BASE_URL$", location.hostname)}/locationdescription/locationdescriptionsonly`,
        this.httpOptions
      )
      .pipe(
        map((resultMessage: ResultMessage) => {
          // this.state.locati  ondescrip  tions$.n  ext(
          //   _.orderBy(products, "ProductNameNl", "asc")
          // );
          // let locationdescriptions: LocationDescription[] =
          //   resultMessage.Data as LocationDescription[];
          // let message: string = resultMessage.Message;

          this.messageService.showMessage(resultMessage.Message);

          return resultMessage.Data as LocationDescription[];
        })
      );
  }

  public updateCurrentLocationDescription(
    locationDescription: LocationDescription,
    userLog: UserLog[],
  ): Observable<any> {
    return this.http
      .post<ResultMessage>(
        `${environment.api.replace("$BASE_URL$", location.hostname)}/locationdescription/update`,
        locationDescription,
        //    this.state.currentLocationDescription$.value,
        this.httpOptions
      )
      .pipe(
        map((r) => {
          let message: string =
            r.Message +
            " : " +
            locationDescription?.Address?.AddressStreet +
            " " +
            locationDescription?.Address?.AddressNumber +
            " " +
            locationDescription?.Address?.AddressPostalcode +
            " " +
            locationDescription?.Address?.AddressTown +
            " ";
          if (r.Message != "") {
            if(r.Message.indexOf("Error bij document generatie") > -1 ){
              this.messageService.showMessage(r.Message.split("Error bij document generatie")[1]);
              userLog.push({ message: message, sort: userLog.length });
              return "Error bij document generatie";
            } else {
              this.messageService.showMessage(message);
              userLog.push({ message: message, sort: userLog.length });
            }
          } else {
            userLog.push({
              message: " => " + message,
              sort: userLog.length,
            });
          }
          return null;
        })
      );
  }

  public unlockLocationDescription(
    locationDescription: LocationDescription,
    userLog: UserLog[]
  ): Observable<any> {
    return this.http
      .post<ResultMessage>(
        `${environment.api.replace("$BASE_URL$", location.hostname)}/locationdescription/unlock`,
        locationDescription,
        this.httpOptions
      )
      .pipe(
        map((r) => {
          if (r.Message != "") {
            this.messageService.showMessage(r.Message);
            userLog.push({
              message: r.Message,
              sort: userLog.length,
            });
          } else {
            userLog.push({
              message: "ontgrendeled",
              sort: userLog.length,
            });
          }
        })
      );
  }
  public copyLocationDescription(
    locationDescription: LocationDescription
  ): Observable<any> {
    return this.http
      .post<ResultMessage>(
        `${environment.api.replace("$BASE_URL$", location.hostname)}/locationdescription/copy`,
        locationDescription,
        this.httpOptions
      )
      .pipe(
        map((r) => {
          //  if (r.Message != "") {
          this.messageService.showMessage(r.Message);
          //  userLog.push(r.Message);
          // } else {
          //  userLog.push("gedupliceerd");
          //}
        })
      );
  }

  // ***********************
  public getItemGroupPrototypes(): Observable<ItemGroup[]> {
    return this.http
      .get<ResultMessage>(
        `${environment.api.replace("$BASE_URL$", location.hostname)}/ItemGroup/itemgroups`,
        this.httpOptions
      )
      .pipe(
        map((resultMessage: ResultMessage) => {
          return <ItemGroup[]>resultMessage?.Data;
        })
      );
  }
  // ***********************
  // Room[]
  // public getRoomsFromDB(): Observable<Room[]> {
  public getRoomPrototypes(): Observable<Room[]> {
    return this.http
      .get<ResultMessage>(`${environment.api.replace("$BASE_URL$", location.hostname)}/room/rooms`, this.httpOptions)
      .pipe(
        map((resultMessage: ResultMessage) => {
          return <Room[]>resultMessage.Data;
        })
      );
  }
  // ***********************
  // ***********************
  public generateAndGetDocument(
    locationDescription: LocationDescription
  ): Observable<ResultMessage> {
    return this.http
      .post<ResultMessage>(
        `${environment.api.replace("$BASE_URL$", location.hostname)}/document/generate`,
        locationDescription,
        this.httpOptions
      )
      .pipe(
        map((resultMessage: ResultMessage) => {
          this.logger.info("DataService  => generateDocument");
          this.logger.info(resultMessage);

          return resultMessage;
        })
      );
  }

  public createUser(user: EraUser): Observable<EraUser> {
    return this.http
      .post<ResultMessage>(`${environment.api.replace("$BASE_URL$", location.hostname)}/user/generate`, user)
      .pipe(
        map((resultMessage: ResultMessage) => {
          this.logger.info("DataService  => generateUser");
          return <EraUser>resultMessage.Data;
        })
      );
  }

  public getUsers(): Observable<EraUser[]> {
    return this.http
      .get<ResultMessage>(`${environment.api.replace("$BASE_URL$", location.hostname)}/user/users`, this.httpOptions)
      .pipe(
        map((resultMessage: ResultMessage) => {
          return <EraUser[]>resultMessage.Data;
        })
      );
  }
}
