import { Injectable } from "@angular/core";
import {
  ApplicationInsights,
  Telemetry,
} from "@microsoft/applicationinsights-web";
import { isThisQuarter } from "date-fns";
import { environment } from "src/environments/environment";
import { Logger } from "../logger/logger";

@Injectable({
  providedIn: "root",
})
export class AppInsightsService {
  public applicationInsights: ApplicationInsights;
  constructor() {
    if (environment.appInsightsInstrumentationKey.trim() == "") {
    } else {
      this.applicationInsights = new ApplicationInsights({
        config: {
          instrumentationKey: environment.appInsightsInstrumentationKey,
          enableCorsCorrelation: true,
          enableAutoRouteTracking: true,

          /* other configuration options */
        }, // title of current webpage is passed to app insigth use pagetitle
      });

      this.applicationInsights.loadAppInsights();
      this.applicationInsights.trackPageView();

      var telemetryInitializer = (envelope: any) => {
        envelope.Component.Version = "v99.99.99";
        envelope.tags["ai.cloud.role"] = environment.InsightsAppName;
      };
      this.applicationInsights.addTelemetryInitializer(telemetryInitializer);
    }
  }

  logPageView(name?: string, url?: string) {
    // option to call manually
    this.applicationInsights.trackPageView({
      name: name,
      uri: url,
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.applicationInsights.trackEvent({ name: name }, properties);
  }

  logMetric(
    name: string,
    average: number,
    properties?: { [key: string]: any }
  ) {
    this.applicationInsights.trackMetric(
      { name: name, average: average },
      properties
    );
  }

  logException(exception: Error, severityLevel?: number) {
    this.applicationInsights.trackException({
      exception: exception,
      severityLevel: severityLevel,
    });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.applicationInsights.trackTrace({ message: message }, properties);
  }
}
