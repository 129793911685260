export const environment = {
  appVersion: require("../../package.json").version,
  production: true,
  api: "https://api.$BASE_URL$",
  sts: "https://sso.aarixa.be",
  appInsightsInstrumentationKey: "c57fc812-03e9-444a-9ba1-e993efd273ad",
  InsightsAppName: "Era-Nobis-FE",
  azureScope: 'api://053be80d-2887-487e-8e86-ca6e537fde75/access_as_user',
  azureClientId: '053be80d-2887-487e-8e86-ca6e537fde75',
  azureRedirectUri: 'https://$BASE_URL$/sso-callback',
  azurePostLogoutRedirectUri: 'https://$BASE_URL$/',
  azureAuthority: 'https://login.microsoftonline.com/be79a9ab-8469-4410-934e-dc6a5368de12',
  home: '/'
};
